@font-face {
  font-family: "Poppins-Regular";
  src: local("Poppins-Regular"),
    url("./fonts/Poppins-Regular/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Bold";
  src: local("Poppins-Bold"),
    url("./fonts/Poppins-Bold/Poppins-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Italic";
  src: local("Poppins-Italic"),
    url("./fonts/Poppins-Italic/Poppins-Italic.ttf") format("truetype");
}