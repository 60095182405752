.about-banner {
  /* background-image: url("../assets/Images/aboutBanner.png"); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 550px;
}

@media screen and (min-width: 768px) {
  .about-banner .head-box span,
  .about-banner p {
    margin-left: 150px !important;
  }
}
