html,
body {
  box-sizing: border-box;
  overflow-x: hidden;
}

.anim {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
}

.head {
  position: absolute;
  top: 60%;
  width: 85%;
  color: black;
}

@media only screen and (min-width: 768px) {
  .head {
    padding: 0 70px;
  }
}

@media only screen and (max-width: 767px) {
  .head {
    padding: 0 20px;
  }
}

/* -FOR LAGRE DEVICES ---- */
@media only screen and (min-width: 1024px) {
  .head .first {
    font-size: 70px;
    font-weight: 500;
  }

  .head .second {
    font-size: 70px;
    font-weight: 500;
  }
}

/* FOR TABLET DEVICES  */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .head .first {
    font-size: 50px;
  }

  .head .second {
    font-size: 50px;
  }
}

/* FOR MOBILE DEVICES  */
@media only screen and (max-width: 767px) {
  .head .first {
    font-size: 30px;
  }

  .head .second {
    font-size: 30px;
  }
}

/* ----CUSTOM BUTTON CSS ------ */

.global-btn {
  background-color: white;
  border-radius: 10px;
  width: fit-content;
  color: #000000;
  padding: 6px 50px;
  border: 1px solid #E17055;
  box-shadow: 0px 4px 8px rgba(225, 112, 85, 0.3);
  font-family: 'Poppins-Regular';
}


.global-btn-bg {
  background-color: white;
  border-radius: 10px;
  width: fit-content;
  color: #000000;
  padding: 8px 50px;
  border: none;
  font-family: 'Poppins-Regular';
}

.pointer {
  cursor: pointer;
}

.text-theme {
  color: #E17055;
}

.text-seond-theme {
  color: #EA4723;
}

.text-third-theme {
  color: #656565;
}

.text-black {
  color: #000000;
}

.text-seond-black {
  color: #1A1A1A;
}

.black-bg {
  background-color: #1A1A1A;
  color: #FFFFFF;
}

.paragraph-underline {
  text-decoration: underline;
  text-decoration-color: #EA4723;
  text-underline-offset: 12px;
  text-decoration-thickness: 2px;
}



/* ----HOME PAGE BANNER RESPONSIVENESS---- */

@media screen and (max-width: 540px) {
  .anim {
    height: 600px !important;
  }

  .anim canvas {
    height: 600px !important;
  }

  .anim .head {
    top: 48%;
  }
}


/* -----FONTS------- */

.font-face-Regular {
  font-family: "Poppins-Regular";
}

.font-face-Bold {
  font-family: "Poppins-Bold";
}

.font-face-Italic {
  font-family: "Poppins-Italic";
}