/* .lngBg {
  background: white;
}

.lngBox {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 0.9rem;
  border-radius: 1rem;
  min-width: 190px;

  background-color: #1a1a1a;
  margin: 0px 10px 0px 10px;
  border: 1px solid #585858;
} */

/* .lngBox:hover {
    transform: scale(1.1);
    border: 1px solid #ff5960;
  } */

@media screen and (max-width: 540px) {
  .lngBg img {
    height: 70px;
    width: auto;
  }
}
