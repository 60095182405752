.contact-form {
  background-color: #e17055;
}

@media screen and (max-width: 912px) {
  .contact-form .container {
    padding-top: 15px !important;
    padding-bottom: 15px;
  }

  .contact-form .content-box {
    margin-top: 10px !important;
  }
}

@media screen and (max-width: 375px) {
  .contact-form .inner-box1 {
    font-size: 12px;
    padding: 10px;
  }

  .contact-form .inner-box1 .heading {
    font-size: 16px;
  }

  .contact-form .action-btn {
    font-size: 9px !important;
    padding: 6px 22px;
  }
}

@media screen and (max-width: 425px) {
  .contact-form .inner-box1 {
    padding: 0 5px;
  }
}

/* ------------ */

.contact-form .action-btn {
  background-color: white;
  font-size: 11px;
  padding: 8px 22px;
  border: none;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 145px;
}

@media screen and (max-width: 540px) {
  .contact-form .address-box {
    width: 80%;
  }
}

.contact-form .inner-box1 {
  background-color: #2d3436;
  border-radius: 10px;
}

.contact-form .inner-box {
  background-color: #2d3436;
  border-radius: 10px;
}

.contact-form .first-box {
  width: 100%;
}

.contact-form .first-box .name {
  width: 50%;
}

.contact-form .first-box .email {
  width: 50%;
}

@media screen and (max-width: 540px) {
  .contact-form .first-box .name {
    width: 100%;
  }

  .contact-form .first-box .email {
    width: 100%;
  }

  .contact-form .first-box .name .input-name {
    width: 100% !important;
  }

  .contact-form .input-email {
    width: 100% !important;
  }
}

.contact-form .first-box .name .input-name {
  width: 90%;
  padding: 8px;
  background-color: transparent !important;
  border: 1px solid rgba(255, 255, 255, 0.15);
  color: white;
}

.contact-form .first-box .input-email {
  width: 90%;
  padding: 8px;
  background-color: transparent !important;
  border: 1px solid rgba(255, 255, 255, 0.15);
  color: white !important;
}

.contact-form .input-subject {
  width: 100%;
  padding: 8px;
  background-color: transparent !important;
  border: 1px solid rgba(255, 255, 255, 0.15);
  color: white !important;
}

.contact-form textarea {
  width: 100%;
  background-color: transparent !important;
  border: 1px solid rgba(255, 255, 255, 0.15);
  color: white !important;
}

.contact-form input:focus {
  outline: none !important;
}

.contact-form textarea:focus {
  outline: none !important;
}

.contact-form .inner-box1 .top-spacing {
  height: 10px !important;
}

@media screen and (max-width: 320px) {
  .content-box {
    display: none;
  }
}
