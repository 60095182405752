/* .portfolio-main .overlay-image {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    transform: translateY(100%);
    transition: transform 0.3s ease-in-out;
}

.portfolio-main .image-box:hover .overlay-image {
    transform: translateY(0);
} */



/* ------SECOND------ */

.portfolio-main {
    background-image: url('../assets/Images/portfolioBg.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

}

/* .portfolio-main .nav-item button {
    font-family: 'Poppins-Bold' !important;
} */

.portfolio-main .image-container {
    position: relative;
    overflow: hidden;
}

.portfolio-main .overlay-image {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    transform: translateY(100%);
    transition: transform 0.3s ease-in-out;
}

.portfolio-main .first-image {
    transition: transform 0.3s ease-in-out;
}

.portfolio-main .image-box:hover .overlay-image {
    transform: translateY(0);
}

.portfolio-main .nav-tabs .nav-link {
    border: none !important;
}

.portfolio-main .nav-tabs {
    border: none !important;

}

.portfolio-main .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: #EA4723 !important;
    text-decoration: underline !important;
    text-underline-offset: 20px !important;
    text-decoration-thickness: 2px !important;
    font-weight: bold !important;
    padding: 10px !important;
}

.portfolio-main .nav-link {
    color: #000000 !important;
    font-weight: bold !important;
}

.portfolio-main .image-container:hover img {
    transform: scale(1.1);
    transition: all 0.8s;
}

.portfolio-main .image-container:hover {
    /* transform: scale(1.1); */
    transition: all 0.8s;
    margin-top: -20px;
}