/* PopupContactForm.css */
.popup-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #E17055;
    z-index: 9999;
    padding: 15px;
    max-width: 540px;
    border-radius: 50px;
    box-shadow: inset 0 0 25px #EA4723;
}

.popup-container p {
    font-size: 12px;
}


.popup-content {
    /* background-color: #E17055; */
    padding-left: 45px;
    padding-right: 45px;
    border-radius: 50px;
    /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5); */
}

.popup-container .popup-btn {
    background-color: #1A1A1A;
    border-radius: 10px;
    width: fit-content;
    color: white;
    padding: 10px 50px;
    border: none;
    font-size: 11px;
}

.popup-container .close-popup {
    cursor: pointer;
}


.popup-container input,
textarea {
    background-color: white;
    border: 1px solid #E17055;
    padding: 10px;
    font-size: 11px !important;
    border-radius: 10px;
}

.popup-container input,
textarea:focus {
    outline: none;
}

@media screen and (max-width: 768px) {
    .popup-content {
        padding-left: 20px;
        padding-right: 20px;

    }
}

@media screen and (max-width: 540px) {
    .popup-container {
        width: 315px !important;
        top: 55%;
    }
}

@media screen and (min-width: 280px) and (max-width:280px) {
    .popup-container {
        width: 265px !important;
    }
}