.offcanvas {
    width: 100vw !important;
    height: 100vh !important;
    background-image: url('../assets/Images/offCanvasBg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.nav-logo {
    height: 40px;
}

.offcanvas-header {
    justify-content: start !important;
}

.header-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
}

.header-main .header-links {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    text-transform: uppercase;
    cursor: pointer;
}

.header-main .header-icons {
    display: flex;
    align-items: center;
    justify-content: center;
    /* position: absolute;
    bottom: 120px;
    left: 0;
    right: 0; */
    margin-top: 30px;
    cursor: pointer;
}


@media screen and (min-width: 1024px) {
    .header-main .hide-box {
        min-height: 100px;
    }
}

@media screen and (max-width:540px) {
    /* .header-main {
        justify-content: start;
    } */

    .header-main .header-links {
        flex-direction: column;
        align-items: start;
    }

    .header-main .header-links h2 {
        font-size: 20px;
    }

    .header-main .header-icons {
        bottom: 50px;
    }
}

@media screen and (max-width:375px) {
    .navbar .nav-logo {
        height: 45px;
        width: auto;
    }
}


.navbar {
    padding: 0 !important;
}

.navbar-toggler-icon {
    background-image: url('../assets/Images/headerIcon.png');
    background-repeat: no-repeat;
    background-position: center;
}

.navbar-toggler {
    padding: 2px !important;
    transition: none !important;
    border: none !important;
}

.navbar-toggler:focus,
.btn-close:focus {
    box-shadow: none !important;
}